import { Component } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { CommonUtillService } from './services/util/common-utill.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  closeApp() {
    App.exitApp();
  }
  constructor(
    private platform: Platform,
    private commonSvc: CommonUtillService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Force light mode
      document.body.setAttribute('color-theme', 'light');
    });
  }

  goToGopassport() {
    this.commonSvc.goToGopassport();
  }
}
