import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentDTO } from 'src/app/interface/dto/agent.dto';
import { ClientDTO } from 'src/app/interface/dto/client.dto';
import { HealthDTO } from 'src/app/interface/dto/health.dto';
import { InvestmentDTO } from 'src/app/interface/dto/investment.dto';
import { PolicyDataDTO } from 'src/app/interface/dto/policy-data.dto';
import { PolicyDTO } from 'src/app/interface/dto/policy.dto';
import { RidersDTO } from 'src/app/interface/dto/riders.dto';
import { SignatureData } from 'src/app/interface/dto/signature-data.dto';

interface AppState {
  agentNum: string | null;
  deviceId: string | null;
  transNum: string | null;
  selectedPolicyNum: string | null;
  selectedPolicyDetail: PolicyDTO | null;
  riderSrc: RidersDTO[] | null;
  riderEdits: RidersDTO[] | null;
  clientEdits: ClientDTO[] | null;
  clientRelEdits: ClientDTO[] | null;
  policyDetailEdit: PolicyDataDTO | null;
  isRevision: boolean | null;
  agentInfo: AgentDTO | null;
  investment: InvestmentDTO[] | null;
  healthList: HealthDTO[] | null;
  signatures: { [key: string]: SignatureData } | null;
  signaturePlace: string | null;
}

type StateKey = keyof AppState;

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private readonly STORAGE_KEY = 'appState';
  private readonly initialState: AppState = {
    agentNum: null,
    deviceId: null,
    transNum: null,
    selectedPolicyNum: null,
    selectedPolicyDetail: null,
    riderSrc: null,
    riderEdits: null,
    clientEdits: null,
    clientRelEdits: null,
    policyDetailEdit: null,
    isRevision: null,
    agentInfo: null,
    investment: null,
    healthList: null,
    signatures: null,
    signaturePlace: null,
  };

  private state = new BehaviorSubject<AppState>(
    this.loadState() || this.initialState
  );

  // Selectors
  readonly state$ = this.state.asObservable();
  readonly agentNum$ = this.select('agentNum');
  readonly deviceId$ = this.select('deviceId');
  readonly transNum$ = this.select('transNum');
  readonly selectedPolicyNum$ = this.select('selectedPolicyNum');
  readonly selectedPolicyDetail$ = this.select('selectedPolicyDetail');
  readonly riderSrc$ = this.select('riderSrc');
  readonly riderEdits$ = this.select('riderEdits');
  readonly clientEdits$ = this.select('clientEdits');
  readonly clientRelEdits$ = this.select('clientRelEdits');
  readonly policyDetailEdit$ = this.select('policyDetailEdit');
  readonly isRevision$ = this.select('isRevision');
  readonly agentInfo$ = this.select('agentInfo');
  readonly investment$ = this.select('investment');
  readonly healthList$ = this.select('healthList');
  readonly signatures$ = this.select('signatures');
  readonly signaturePlace$ = this.select('signaturePlace');

  get currentState(): AppState {
    return this.state.value;
  }

  private loadState(): AppState | null {
    try {
      const savedState = localStorage.getItem(this.STORAGE_KEY);
      return savedState ? JSON.parse(savedState) : null;
    } catch (error) {
      console.error('Error loading state:', error);
      return null;
    }
  }

  private select<K extends StateKey>(key: K): Observable<AppState[K]> {
    return this.state.pipe(map((state) => state[key]));
  }

  private setState<K extends StateKey>(key: K, value: AppState[K]): void {
    const newState = {
      ...this.state.value,
      [key]: value,
    };

    this.state.next(newState);
    this.saveState(newState);
  }

  private saveState(state: AppState): void {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
    } catch (error) {
      console.error('Error saving state:', error);
    }
  }

  resetState(): void {
    const { agentNum, deviceId, agentInfo } = this.state.value;
    const partialResetState = {
      ...this.initialState,
      agentNum,
      deviceId,
      agentInfo,
    };

    this.state.next(partialResetState);
    this.saveState(partialResetState);
  }

  resetAllState(): void {
    const partialResetState = {
      ...this.initialState,
    };

    this.state.next(partialResetState);
    this.saveState(partialResetState);
  }

  // Setters
  setAgentNum(value: string | null): void {
    this.setState('agentNum', value);
  }

  setDeviceId(value: string | null): void {
    this.setState('deviceId', value);
  }

  setTransNum(value: string | null): void {
    this.setState('transNum', value);
  }

  setSelectedPolicyNum(value: string | null): void {
    this.setState('selectedPolicyNum', value);
  }

  setSelectedPolicyDetail(value: PolicyDTO | null): void {
    this.setState('selectedPolicyDetail', value);
  }

  setRiderSrc(value: RidersDTO[] | null): void {
    this.setState('riderSrc', value);
  }

  setRiderEdits(value: RidersDTO[] | null): void {
    this.setState('riderEdits', value);
  }

  setClientEdits(value: ClientDTO[] | null): void {
    this.setState('clientEdits', value);
  }

  setClientRelEdits(value: ClientDTO[] | null): void {
    this.setState('clientRelEdits', value);
  }

  setPolicyDetailEdit(value: PolicyDataDTO | null): void {
    this.setState('policyDetailEdit', value);
  }

  setIsRevision(value: boolean | null): void {
    this.setState('isRevision', value);
  }

  setAgentInfo(agentInfo: AgentDTO) {
    console.log('Setting agent info:', agentInfo);
    this.setState('agentInfo', agentInfo);
  }

  setInvestment(value: InvestmentDTO[] | null): void {
    this.setState('investment', value);
  }

  setHealthList(value: HealthDTO[] | null): void {
    this.setState('healthList', value);
  }

  setSignatures(value: { [key: string]: SignatureData } | null): void {
    this.setState('signatures', value);
  }

  setSignaturePlace(value: string | null): void {
    this.setState('signaturePlace', value);
  }
}
