import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, AlertController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { AppStateService } from '../states/app-state.service';

declare var webkit: any;
@Injectable({
  providedIn: 'root',
})
export class CommonUtillService {
  constructor(
    private loaderCtrl: LoadingController,
    private alertCtrl: AlertController,
    private appState: AppStateService,
    private router: Router
  ) {}

  createLoader(): Promise<HTMLIonLoadingElement> {
    return new Promise((resolve) => {
      resolve(
        this.loaderCtrl.create({
          message: 'loading',
          //  spinner: null,
          //  cssClass: 'custom-class custom-loading',
        })
      );
    });
  }

  async createAlert(header: string, message: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: 'Tutup',
          cssClass: 'alert-button',
        },
      ],
      cssClass: 'custom-alert',
    });

    await alert.present();
    await alert.onDidDismiss(); // This ensures we wait for user interaction
  }

  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, encodeURIComponent(val[index]));
    }
    return str;
  }

  formatDate(date: string | Date): string {
    let d: Date;

    if (typeof date === 'string') {
      // Parse DD-MM-YYYY format
      const [day, month, year] = date.split('-');
      d = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      d = date;
    }

    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${day < 10 ? '0' : ''}${day}-${
      month < 10 ? '0' : ''
    }${month}-${year}`;
  }

  calculateAge(dateOfBirth: string | Date): number {
    // Set up dates using Asia/Jakarta timezone
    const today = new Date();
    let birthDate: Date;

    if (typeof dateOfBirth === 'string') {
      // Parse DD-MM-YYYY format
      const [day, month, year] = dateOfBirth.split('-');
      birthDate = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      birthDate = dateOfBirth;
    }

    // Calculate base age
    let age = today.getFullYear() - birthDate.getFullYear();

    // Get month difference (adding 1 because getMonth() returns 0-11)
    const currentMonth = today.getMonth() + 1;
    const birthMonth = birthDate.getMonth() + 1;
    const monthDiff = currentMonth - birthMonth;

    // Adjust age based on month difference
    if (monthDiff < 0) {
      // If birthday hasn't occurred this year yet
      age = age - 1;

      // Calculate months until birthday (add 12 to current month for proper calculation)
      const monthsUntilBirthday = currentMonth + 12 - birthMonth;

      if (monthsUntilBirthday >= 7) {
        age = age + 1;
      }
    } else {
      // If birthday has occurred this year
      if (monthDiff >= 7) {
        age = age + 1;
      }
    }

    return age;
  }

  countExactAge(dateOfBirth: string): number {
    // Parse DD-MM-YYYY format
    const [day, month, year] = dateOfBirth.split('-');
    const birthDate = new Date(Number(year), Number(month) - 1, Number(day));

    // Get current date
    const today = new Date();

    // Calculate years
    let years = today.getFullYear() - birthDate.getFullYear();

    // Adjust years if birthday hasn't occurred yet this year
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      years--;
    }

    return years;
  }

  calculateAgeInMonths(dob: Date): number {
    const today = new Date();
    let months = (today.getFullYear() - dob.getFullYear()) * 12;
    months -= dob.getMonth();
    months += today.getMonth();
    return months;
  }

  getNumericValue(str: String): number {
    return str ? parseInt(str.replace(/\./g, ''), 10) : 0;
  }
  getFloatValue(str: string): number {
    return str ? parseFloat(str.replace(/\./g, '').replace(/,/g, '.')) : 0.0;
  }

  toRp(num: number) {
    const formatter = new Intl.NumberFormat('id-ID', {
      currency: 'IDR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(num);
  }

  toRpNoFrac(num: number) {
    const formatter = new Intl.NumberFormat('id-ID', {
      currency: 'IDR',
    });
    return formatter.format(num);
  }

  toRpNoFracStar(num: number) {
    if (num <= 0) {
      return '*';
    }
    const formatter = new Intl.NumberFormat('id-ID', {
      currency: 'IDR',
    });
    return formatter.format(num);
  }

  parseIndonesianCurrency(str: string): number {
    return parseFloat(
      str
        .replace('Rp', '') // Remove currency symbol
        .replace(/\s/g, '') // Remove whitespace
        .replace(/\./g, '') // Remove thousand separators
        .replace(',', '.') // Replace decimal comma with dot
        .trim()
    );
  }

  decrypt(cipherText: string): string {
    // DEV
    const secretKey: string = 'PEAzCTaYwlpZdRbD3VY55T9GYm325HkL';

    // PROD
    // const secretKey: string = 'ESvJtlijMNjEbNUw2LCn4vMhldLI1jUq';

    try {
      const key = CryptoJS.enc.Utf8.parse(secretKey);
      const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16));
      const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption failed:', error);
      return '';
    }
  }

  toIDR(price: number | undefined) {
    if (!price) return '0';
    return `Rp ${this.toRp(price)}`;
  }

  async validateToHome() {
    const alert = await this.alertCtrl.create({
      header: 'Konfirmasi',
      message: 'Data tidak akan disimpan. Apakah Anda ingin melanjutkan?',
      buttons: [
        {
          text: 'Batal',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Setuju',
          handler: () => {
            console.log('Confirm Okay');
            this.router.navigate(['/home'], { replaceUrl: true });
          },
        },
      ],
    });

    await alert.present();
  }

  goToGopassport() {
    // Store data in window object for access
    this.appState.resetAllState();
    (window as any).pdfData = {
      content: 'base64Content',
      filename: 'filename' + '.pdf',
      mimeType: 'application/pdf',
    };

    try {
      if (webkit.messageHandlers) {
        webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            type: 'close-app',
          })
        );
      }
    } catch (e) {
      console.error('Message send error:', e);
    }
  }

  getFreq(paymentFreq: string): number {
    switch (paymentFreq) {
      case 'M':
        return 12;
      case 'Q':
        return 4;
      case 'H':
        return 2;
      case 'A':
        return 1;
      default:
        return 1;
    }
  }
}
